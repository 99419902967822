import * as React from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CSectTitle,
  CLocalNav,
  CWideMedia,
  CBtnList,
  LAboutContact,
} from '../../components/_index';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        data={{
          title: {
            main: 'ANNIVERSARY',
            sub: 'お祝い・記念日',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/anniversary/kv.png',
              },
              imgSp: {
                src: '/assets/images/anniversary/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [],
            current: {
              label: 'お祝い・記念日',
            },
          }}
        />
      </CJumbotron>
      <section className="l_sect02">
        <LWrap>
          <CLocalNav
            data={[
              {
                img: {
                  src: '/assets/images/anniversary/img_localnav.png',
                  alt: '',
                },
                link: {
                  href: '/anniversary/propose/',
                },
                title: {
                  main: 'プロポーズ',
                },
              },
              {
                img: {
                  src: '/assets/images/anniversary/img_localnav02.png',
                  alt: '',
                },
                link: {
                  href: '#stay_plan',
                },
                title: {
                  main: '宿泊プラン',
                },
              },
              {
                img: {
                  src: '/assets/images/anniversary/img_localnav03.png',
                  alt: '',
                },
                link: {
                  href: '#restaurants_plan',
                },
                title: {
                  main: 'レストランプラン',
                },
              },
              // {
              //   img: {
              //     src: '/assets/images/anniversary/img_localnav04.png',
              //     alt: '',
              //   },
              //   link: {
              //     href: '#gift',
              //   },
              //   title: {
              //     main: 'ギフト券',
              //   },
              // },
            ]}
          />
        </LWrap>
      </section>
      <section className="l_sect u_bgWhite">
        <LWrap>
          <CSectTitle title={{ ja: <>プロポーズ</>, en: <>PROPOSE</> }} />
          <CWideMedia
            data={[
              {
                img: {
                  src: '/assets/images/anniversary/img_propose.png',
                  alt: '',
                },
                text: (
                  <>
                    横浜ロイヤルパークホテルは、横浜の街を一望する、空と隣り合うホテル。
                    <br />
                    非日常の空間で、大切なあの人へ一生に一度のプロポーズ。
                    <br />
                    今日がおふたりの大切な記念日になりますように。
                  </>
                ),
              },
            ]}
          />
          <CBtnList
            data={[
              {
                size: 'large',
                label: 'プロポーズ',
                link: {
                  href: `/anniversary/propose/`,
                },
              },
            ]}
          />
        </LWrap>
      </section>

      <section className="l_sect" id="stay_plan">
        <LWrap>
          <CSectTitle title={{ ja: <>宿泊プラン</>, en: <>STAY PLAN</> }} />
          <CWideMedia
            data={[
              {
                img: {
                  src: '/assets/images/anniversary/img_accommodations.png',
                  alt: '',
                },
                text: (
                  <>
                    誕生日、結婚記念日など大切な人と過ごす記念日は特別なもの。
                    <br />
                    ケーキ付きやシャンパン付きなど、非日常のサプライズ付きプランで、最高の1日を叶えて。
                  </>
                ),
              },
            ]}
          />
          <CBtnList
            data={[
              {
                size: 'large',
                label: '宿泊プラン',
                link: {
                  href: `/anniversary/stay_plan/`,
                },
              },
            ]}
          />
        </LWrap>
      </section>
      <section className="l_sect" id="restaurants_plan">
        <LWrap>
          <CSectTitle
            title={{
              ja: <>レストランプラン</>,
              en: (
                <>
                  RESTAURANT <br className="u_sp" />
                  PLAN
                </>
              ),
            }}
          />
          <CWideMedia
            data={[
              {
                img: {
                  src: '/assets/images/anniversary/img_restaurant.png',
                  alt: '',
                },
                text: (
                  <>
                    レストランでの特別なひとときに、より華を添えるプランをご用意しました。
                    <br />
                    心を込めたお料理とおもてなしで素敵な時間を演出いたします。
                  </>
                ),
              },
            ]}
          />
          <CBtnList
            data={[
              {
                size: 'large',
                label: 'レストランプラン',
                link: {
                  href: `/anniversary/restaurants_plan/`,
                },
              },
            ]}
          />
        </LWrap>
      </section>
      <section className="u_mbMedium">
        <LWrap>
          <ul className="c_noteList">
            <li>画像は全てイメージです。</li>
          </ul>
        </LWrap>
      </section>
      <LAboutContact currentIndex={0} />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
